<template>
  <router-link
    class="w-full menu_element_block flex align-middle items-center px-4 2xl:px-5 py-4 2xl:py-5 mt-5"
    :to="'/' + path"
    :class="{
      'active-route font-c5':
        isActiveRoute,
      'inactive-route font-c5 text-c16':
        !isActiveRoute,
    }"
  >
    <icon
      :data="icon"
      height="26"
      width="26"
      class="menu_element_icon"
      original
    />
    <div class="menu_element ml-4 text-c16 2xl:text-c18 w-3/4 text-left">
      {{ menu }}
    </div>
  </router-link>
</template>

<script>
import { useRoute } from 'vue-router';
import right from '../../assets/icons/right.svg';

export default {
  name: 'YourComponentName',
  props: {
    menu: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: ''
    },
    path: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      icons: {
        right
      }
    };
  },
  computed: {
    isActiveRoute() {
      const currentRoute = useRoute();
      return currentRoute.path === `/${this.path}`;
    }
  }
};
</script>


<style lang="scss">
@import '../../assets/styles/sass/mixin';
.active-route {
  color: #241A72;
  size: 18px;
  background-color: #D1C6FE;
  border-radius: 12px;
}
.inactive-route {
  color: #EBEFF4;
}
.menu_element {
  color: #fff;
 }
</style>