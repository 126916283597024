<template>
  <div
    class="w-full rounded-10 menu_element transition-all duration-500 ease-in-out"
    :class="{'bg pb-5': activeD}"
  >
    <div
      class="menu_element_block flex align-middle items-center 2xl:pl-5 rounded-11 "
      :class="{'activeR': sendPath || activeD}"
      @click="openDrag"
    >
      <icon
        :data="icon"
        height="25"
        width="25"
        class="menu_element_icon"
        color="#8E8EA9"
        original
      />
      <div class="menu_element ml-4 text-c18 w-3/4 text-left">
        {{ menu }}
      </div>
      <icon
        v-if="!activeD"
        :data="icons.ic_down"
        height="25"
        width="15"
        class="menu_element_icon"
        original
      />
      <icon
        v-if="activeD"
        :data="icons.ic_up"
        height="25"
        width="15"
        class="menu_element_icon"
        color="#fff"
        original
      />
    </div>
    <div
      v-if="activeD"
      class="mt-3 2xl:pl-7 pb-5"
    >
      <div
        v-for="(item, index) in allItem"
        :key="index"
        class="text-left text-c16 tailltext text cole px-2 py-3 pl-5 cursor-pointer flex items-center gap-2 mr-3 "
        :class="{'font-c6 change': item.path === position}"
        @click="selected(item.path)"
      >
        <icon
          :data="icons.Point"
          height="7"
          width="7"
          class="menu_element_icon"
          color="#8E8EA9"
          original
        />
        {{ item.label }}
      </div>
    </div>
  </div>
</template>

<script>
import ic_up from '../../assets/icons/ic_up.svg'
import ic_down from '../../assets/icons/ic_down.svg'
import Point from '../../assets/icons/point.svg'


export default {
  name: '',
  props: {
    menu: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: ''
    },
    path: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    allItem: {
      type: Array,
      default: null
    }
  },

  data () {
    return {
      icons: {
        ic_up,
        ic_down,
        Point
      },
      activeD: false,
      position: null
    }
  },

  computed: {
    // eslint-disable-next-line vue/return-in-computed-property
    sendPath: function () {
      let path = window.location.pathname
      path = path.substr(1, path.length)
      if (this.allItem.indexOf(path) > -1) {
        return true
      } else {
        return false
      }
    },
  },

  created(){
    let path = window.location.pathname
    this.page = path.substr(1, path.length)
    this.verif()
  },

  methods: {
    openDrag(){
      this.activeD =! this.activeD
    },

    verif () {
      let path = window.location.pathname
      this.position = path.substr(1, path.length).toLowerCase()
    },

    selected(path) {
      this.page = path;
      this.position = path;
      this.$router.push('/' + path);
    }
  }
}
</script>

<style lang="scss">
@import '../../assets/styles/sass/mixin';

@media (max-width: 1440px) {
  .tailltext{
    font-size: 13px;
  }
}
.bg{
  background-color: $blue-dark;
  padding:0px 5px
}

.cole{
  color: #fff;
}
.cole:hover{
  background-color: $blue-dark;
  border-radius: 10px
}
.change{
  color: #241A72;
  background-color: #D1C6FE;
  border-radius: 10px;
  font-weight: 700
}
.change:hover{
  background-color: #D1C6FE;
}
.menu_element_block{
  margin-top: 30px;
  position: relative;
  cursor: pointer;
  width: 100%;
  font-weight: 400;
  letter-spacing: 0.0168em;
  color: #8E8EA9;
  .menu_element{
    color: #fff;
  }
}
.activeR{
  height: 48px;
  color: white;
  border-radius: 10px;
  .menu_element{
    color: #fff;

  }
  .avtive_position{
    visibility: visible;
    background: none;
  }
  .menu_element_icon{
    path{
      fill: white!important;
    }
  }
}
.menu_element_block:hover, .activeR:hover {
  color: #fff;
  .menu_element_icon{
    path{
      fill: #fff!important;

    }
  }
}
</style>
