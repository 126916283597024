<template>
  <div class="header bg-white px-6 py-5 fixed z-50">
    <div class="flex justify-between items-center">
      <div class="credit flex justify-start w-full font-c5 text-c26 text-60">
        Crédits
      </div>
      <div class="flex items-center gap-8 w-11/12 justify-end">
        <div
          class="action cursor-pointer"
        >
          <icon
            :data="icons.notif"
            height="30"
            width="30"
            original
          />
        </div>
        <div
          @click="activelogout = true"
          class="flex items-center gap-2 openlogout cursor-pointer rounded-100 p-1.5"
        >
          <div class="profil flex justify-center items-center rounded-100">
            <icon
              :data="icons.user"
              height="28"
              width="28"
              original
              color="white"
            />
          </div>
          <div class="cursor-pointer">
            <div
              v-if="user !== null"
              class="font-c5 flex justify-start text-c16 2xl:text-c18  text-black"
            >
              {{ user.firstName }} {{ user.lastName }}
            </div>
            <div class="role flex justify-start font-c5 text-c13">
              Adminstrateur
            </div>
          </div>
          <div class="ml-6 2xl:ml-8">
            <icon
              :data="icons.downs"
              height="14"
              width="13"
              original
              class="mx-2 cursor-pointer"
              @click="activelogout = true"
            />
          </div>
        </div>
      </div>
    </div>

    <logout
      v-if="activelogout"
      :activation="activelogout"
      @oga="retourOut"
    />
  </div>
</template>

<script>
import downs from '../../assets/icons/down.svg'
import defill from '../../assets/icons/defill.svg'
import user from '../../assets/icons/userS.svg'
import notif from '../../assets/icons/notification.svg'
import logout from '../popup/logOut'

export default {
  name: 'TheHeader',
  components: {
    logout,
  },

  data () {
    return {
      name: 'patou',
      activelogout: false,
      showMobileMenu: false,
      activeEvenement: false,
      isLog: null,
      activeLogin: false,
      activDefile: false,
      activeAgence: false,
      broker: null,
      user: null,
      icons: {
        downs,
        defill,
        notif,
        user
      },
    }
  },

  mounted () {
    if (this.$store.getters.token !== null) {
      this.user = this.$store.getters.trackUser.user
    } else {
      window.location.reload()
    }
  },

  methods: {
    retourOut(answwer){
      this.activelogout = answwer
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../assets/styles/sass/variables";

.svg-fill {
  fill: transparent !important;
}
svg{
  fill: transparent !important;
}

.header {
  width: 83.5%;
}
.credit {
  color: #5D666A;
}
.role {
  color: #A8B1B7;
}
.openlogout{
  background-color: #ECF0F6;
}
.profil {
  width: 50px;
  height: 50px;
  background-color: #F35D49;
}
</style>
