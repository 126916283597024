<template>
  <div class="the_sidebar pt-5 pb-6 fixed ">
    <div class="logo flex items-center justify-between pl-8 pr-5 pt-3 pb-5">
      <img
        src="../../assets/images/png/logo.png"
        width="149"
        height="48"
        alt=""
      >
      <div class="cursor-pointer">
        <icon
          :data="icons.ic_opensidebar"
          height="30"
          width="30"
          class="ic_opensidebar"
          original
        />
      </div>
    </div>

    <div class="defilOption px-3.5 pb-10 mt-2 ">
      <div
        class="rounded-10 font-c7"
        v-if="trackRole('Tableau de bord')"
      >
        <menu-element
          menu="Tableau de bord"
          :icon="icons.dashboard"
          path="dashboard"
        />
      </div>

      <div class="mt-6 BGDashbord">
        <side-menu-special
          v-if="trackRole('Commerces')"
          key="1"
          menu="Clientèle"
          :icon="icons.clientele"
          :all-item="[
            { label: 'Entreprises/Commerces', path: 'commerce' },
            { label: 'Coupons', path: 'coupons' },
          ]"
        />
      </div>

      <div class="mt-6">
        <side-menu-special
          v-if="trackRole('Commerces')"
          key="1"
          menu="Crédits"
          :icon="icons.Credits"
          :all-item="[
            { label: 'Demandes de crédits', path: 'commerciaux' },
            { label: 'Crédits accordés', path: 'coupons' },
            { label: 'Offres de crédit', path: 'credit' },
          ]"
        />
      </div>

      <div class="mt-6">
        <side-menu-special
          v-if="trackRole('Commerces')"
          key="1"
          menu="Partenaires"
          :icon="icons.Partenaire"
          :all-item="[
            { label: 'SFD partenaires', path: 'commerciaux' },
            { label: 'Programmes', path: 'coupons' },
          ]"
        />
      </div>

      <div class="mt-6">
        <side-menu-special
          :key="index"
          v-if="trackRole('Commerces')"
          menu="Administration"
          :icon="icons.Administration"
          :all-item="[
            { label: 'Commerciaux', path: 'commerciaux' },
            { label: 'Experts comptables', path: 'charteredAccountants' },
            { label: 'Demandes DGI', path: 'demandes' },
            { label: 'Droits d’accès', path: 'droits' }
          ]"
        />
      </div>
      <!--
      <div class="mt-6 pl-10 pr-6">
        <div class="text-left text-c13 text-black">
          CLIENTÈLE
        </div>
      </div>

      <div class="mt-6">
        <div class="mt-2">
          <menu-element
            v-if="trackRole('Commerces')"
            menu="Entreprises"
            :icon="icons.commerce"
            path="commerce"
          />
        </div>

        <div class="pt-2">
          <menu-element
            v-if="trackRole('Coupons')"
            menu="Coupons"
            :icon="icons.coupon"
            path="coupons"
          />
        </div>
      </div> -->

      <!-- <div class="mt-6 pl-10 pr-6">
        <div class="text-left text-c13 text-black">
          ADMINISTRATION
        </div>
      </div> -->


      <div class="mt-6">
        <!-- <div class="mt-2">
          <menu-element
            v-if="trackRole('Commerciaux')"
            menu="Commerciaux"
            :icon="icons.commerciaux"
            path="commerciaux"
          />
        </div>
        <div class="mt-2">
          <menu-element
            v-if="trackRole('Commerciaux')"
            menu="Experts comptables"
            :icon="icons.commerciaux"
            path="charteredAccountants"
          />
        </div>

        <div class="pt-2">
          <menu-element
            v-if="trackRole('SFD partenaires')"
            menu="Programme"
            :icon="icons.sfd"
            path="partenaires"
          />
        </div>

        <div class="pt-2">
          <menu-element
            v-if="trackRole('Commerces')"
            menu="Demandes DGI"
            :icon="icons.commerciaux"
            path="demandes"
          />
        </div>

        <div class="pt-2">
          <menu-element
            v-if="trackRole('Droits d’accès')"
            menu="Droits d’accès"
            :icon="icons.droit"
            path="droits"
          />
        </div> -->

        <!-- bouton changement de theme -->
        <!-- <div class="">
          <div class="theme flex items-center justify-between px-3.5 2xl:px-4 py-0.5 2xl:py-1 rounded-12  mt-80">
            <div class="flex items-center gap-2">
              <icon
                :data="icons.ic_moon"
                height="14"
                width="14"
                class="ic_moon"
                original
              />
              <div class="font-medium text-c12 text-th">
                Thème sombre
              </div>
            </div>
            <div @click="turnMode">
              <icon
                :data="icons.ic_themeb"
                height="50"
                width="30"
                class="ic_themeb"
                original
              />
            </div>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import menuElement from './side-menu-element'
import sideMenuSpecial from './side-menu-special.vue'
import dashboard from '../../assets/icons/dashboard.svg'
import logo from '../../assets/icons/whiteLogo.svg'
import user from '../../assets/icons/userS.svg'
import savo from '../../assets/icons/sav.svg'
import agenda from '../../assets/icons/agenda.svg'
import article from '../../assets/icons/article.svg'
import coupon from '../../assets/icons/ic-coupon.svg'
import support from '../../assets/icons/ic-support.svg'
import sfd from '../../assets/icons/ic-sfd.svg'
import commerciaux from '../../assets/icons/ic-commerciaux.svg'
import droit from '../../assets/icons/ic-droit.svg'
import commerce from '../../assets/icons/ic-commerce.svg'
import ic_moon from '../../assets/icons/ic_moon.svg'
import ic_opensidebar from '../../assets/icons/ic_opensidebar.svg'
import ic_themeb from '../../assets/icons/ic_themeb.svg'
import clientele from '../../assets/icons/ic-clientele.svg'
import Credits from '../../assets/icons/ic_credits.svg'
import Partenaire from '../../assets/icons/ic_partenaire.svg'
import Administration from '../../assets/icons/ic_administration.svg'


export default {
  name: 'TheSidebar',
  components: {
    menuElement,
    sideMenuSpecial
  },
  data () {
    return {
      icons: {
        commerce,
        logo,
        dashboard,
        user,
        savo,
        agenda,
        article,
        coupon,
        support,
        sfd,
        commerciaux,
        droit,
        ic_moon,
        ic_opensidebar,
        ic_themeb,
        clientele,
        Credits,
        Partenaire,
        Administration
      },
      user: null,
      allRole: [],
      isDarkMode: true
    }
  },
  mounted () {
    if (this.$store.getters.token !== null) {
      this.user = this.$store.getters.trackUser
      if (this.user.user.userType === 'ADMIN') {
        // console.log(this.user.role)
        this.allRole = this.user.role
        // this.allRole = ['Tableau de bord', 'Commerces', 'Coupons', 'Support client', 'Commerciaux', 'SFD partenaires', 'Droits d’accès']
      }

      if (this.user.user.userType === 'SFDADMIN') {
        this.allRole = this.user.role
        // console.log(this.user.role)
      }

      if (this.user.user.userType === 'SFDCOMMERCIAL') {
        this.allRole = ['Coupons']
      }
    }
  },
  methods: {
    accueil () {
      this.$router.go('/clients')
    },

    trackRole (index) {
      if (this.allRole.indexOf(index) !== -1) {
        return true
      } else {
        return false
      }
    },
    turnMode () {
      this.isDarkMode = !this.isDarkMode
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../assets/styles/sass/variables';

.logo {
  box-shadow: 0px 3.42px 3.42px 0px #00000040;
}
.fececam {
  background-color: #FFFFFF14;
}
.svg-fill {
  fill: transparent;
}
.the_sidebar{
  height: 100vh;
  background-color: #221861;
  width: 80%;
}
.defilOption{
  height: 100%;
  max-height: 90%;
  overflow-y: scroll;
  overflow-x: hidden;
  // width: 0%;
}

.defilOption::-webkit-scrollbar {
  display: none;
}

.theme {
  background-color: #FFFFFF14
}
</style>
