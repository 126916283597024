<template>
  <div class="home_page flex justify-between">
    <div class="sidebar">
      <sidebar class="side" />
    </div>
    <div class="body">
      <div>
        <theHeader class="" />
      </div>
      <div>
        <router-view class="mt-32" />
      </div>
    </div>
  </div>
</template>

<script>
import theHeader from '@/component/helper/header'
import sidebar from '@/component/helper/the-sidebar'

export default {
  name: 'Home',
  components: {
    theHeader,
    sidebar
  }
}
</script>
<style lang="scss" scoped>
@import "../assets/styles/sass/variables";
.home_page {
  background-color: #F0F1F9;
  width: 100%;
  height: 100%;
}
.side {
  width: 16.5%;
  height: 100%;
}
.body {
  width: 83.5%;
  height: 100
}

@media screen and (max-width: 1440px) {
  .home_page {
    background-color: #F0F1F9;
    height: 100%;
  }
  .home_page::-webkit-scrollbar {
    display: none;
  }
}
</style>
